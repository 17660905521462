import React from 'react';
import SingleTeam from '../../../components/SingleTeam/SingleTeam';

const TeamArea = () => {
   return (
      <>
         <section className="team__area pt-115 pb-110">
            <div className="container">
               <div className="row align-items-center mb-55">
                  <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8">
                     <div className="section__title section__title-3 mb-30">
                        <span>Our Team</span>
                        <h2>We help to create visual strategies.</h2>
                     </div>
                  </div>
               </div>
               <div className="row">

                  <SingleTeam image="1" name="Jacob Jayaraj" title="Vice President" />
                  <SingleTeam image="2" name="Gokul Raj" title="Director" />
                  <SingleTeam image="3" name="Vinoth Kumar" title="Digital Project Manager" />
                  <SingleTeam image="4" name="Lakshana Sneha" title="HR Manager" />
                  <SingleTeam image="5" name="Ganesh Kumar" title="Motion Content Creator" />
                  <SingleTeam image="6" name="Sunil Kumar" title="Creative Head" />
                  <SingleTeam image="7" name="Bharath Kumar" title="Data Science" />
                  <SingleTeam image="8" name="Kiran Kumar" title="UI/UX Designer" />
                  <SingleTeam image="9" name="Harish" title="Operations Manager " />
                  <SingleTeam image="10" name="Blessy" title="Manager" />
                  <SingleTeam image="11" name="Najila" title="Project Engineer" />
                  <SingleTeam image="12" name="Praveen" title="Coordinator" />
               </div >
            </div >
         </section >
      </>
   );
};

export default TeamArea;